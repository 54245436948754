<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            Calendario
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reloadView++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reloadView">
            <div class="pa-1">
                <v-row class="white ma-0 rounded-lg">
                    <v-col class="spacerow ma-4">
                        <div style="display: flex; flex-wrap: wrap; justify-content: space-around;">
                            <div>
                                <input type="radio" id="awaiting" value="0" v-model="typeCalendar" />
                                <label class="spacing" for="awaiting"><b> Calendario general</b></label>
                            </div>
                            <div>
                                <input type="radio" id="Attending" value="1" v-model="typeCalendar" />
                                <label class="spacing" for="Attending"><b> Calendario de Peluqueria</b></label>
                            </div>
                            <!-- comentado hasta implementar la funcionalidad de calendario de citas médicas -->
                            <!-- <div>
                                <input type="radio" id="Attending2" value="2" v-model="typeCalendar" />
                                <label class="spacing" for="Attending2"><b> Calendario de Citas médicas</b></label>
                            </div> -->
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="typeCalendar == 0" class="ma-0 mt-3">
                    <v-col class="pa-0">
                        <v-sheet style="height: 120px !important;">
                            <v-toolbar flat style="height: 100px !important" >
                                <v-row align="center" no-gutters class="d-flex bg-white flex-column flex-sm-row spacerow">
  
                                    <v-col cols="12" md="5" sm="5" xs="5" class="d-flex flex-column flex-md-row align-center justify-center justify-md-space-between mb-4 mb-sm-0">
                                        <div class="d-flex align-center justify-center">
                                             <v-btn outlined class="mr-2" color="grey darken-2" @click="setToday">Hoy</v-btn>
                                             <v-btn text small color="grey darken-2" @click="prev">
                                              <v-icon small>mdi-chevron-left</v-icon>
                                             </v-btn>
                                             <v-btn text small color="grey darken-2" @click="next">
                                                 <v-icon small>mdi-chevron-right</v-icon>
                                             </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="2" sm="2" xs="2" class="d-flex align-center justify-center mb-4 mb-sm-0" style="align-content: center;">
                                        <v-toolbar-title v-if="$refs.calendar">
                                                    {{ $refs.calendar.title }}
                                        </v-toolbar-title>
                                    </v-col>
                                    <v-col cols="12" md="5" sm="5" xs="5" class="d-flex justify-center justify-md-end">
                                        <v-menu bottom right>
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                                <span>{{ typeToLabel[type] }}</span>
                                                <v-icon right>mdi-menu-down</v-icon>
                                            </v-btn>
                                            </template>
                                            <v-list>
                                            <v-list-item @click="type = 'day'">
                                                <v-list-item-title>Ver por día</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="type = 'week'">
                                                <v-list-item-title>Ver por semana</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="type = 'month'">
                                                <v-list-item-title>Ver por mes</v-list-item-title>
                                            </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-btn text color="primary" dark style="margin-left: 10px;" @click="openModalByButton">
                                            Agregar cita
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                </v-toolbar>
                        </v-sheet>
                        <v-sheet height="600">
                            <v-calendar style="padding-bottom: 20px;" :locale="locale" ref="calendar" v-model="focus" color="primary" :events="events"
                                :event-color="getEventColor" :type="type" @click:event="showEvent" @click:more="viewDay"
                                @click:date="viewDay" @change="updateRange"></v-calendar>
                            <v-menu v-model="selectedOpen" max-width="400px" :close-on-content-click="false" :activator="selectedElement"
                                offset-x>
                                <v-card color="grey lighten-4" min-width="30px" flat>
                                    <v-toolbar :color="selectedEvent.color" dark>
                                        <v-btn icon @click="delSelectedOpen"
                                            color="error"
                                            v-if="selectedEvent.name != 'Cita de vacunación' && selectedEvent.name != 'Cita de medicación' && selectedEvent.name != 'Cita médica' && selectedEvent.name != 'Cita de peluquería'">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <v-card-text class="m-1" style="background: white;">
                                        <span  v-html="selectedEvent.details"></span>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn text color="secondary" @click="selectedOpen = false">
                                            Cerrar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-row v-if="typeCalendar == 1" class="ma-0 mt-3">
                    <v-col class="pa-0">
                        <v-sheet height="600" rounded="lg">
                            <calendar
                                class="rounded-lg"
                                v-model="focus"
                                @click:event="showEvent"
                                @click:time="openModalByTime"
                                @change="updateRange"
                                :events="events2"
                                height="500px"
                                type="week"
                                hide-controls
                                hide-action>
                            </calendar>
                            <v-menu v-model="selectedOpen" max-width="400px" :close-on-content-click="false" :activator="selectedElement"
                                offset-x>
                                <v-card color="grey lighten-4" min-width="30px" flat>
                                    <v-toolbar :color="selectedEvent.color" dark>
                                        <v-btn icon @click="delSelectedOpen"
                                            color="error"
                                            v-if="selectedEvent.name != 'Cita de vacunación' && selectedEvent.name != 'Cita de medicación' && selectedEvent.name != 'Cita médica' && selectedEvent.name != 'Cita de peluquería'">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <v-card-text class="m-1" style="background: white;">
                                        <span  v-html="selectedEvent.details"></span>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn text color="secondary" @click="selectedOpen = false">
                                            Cerrar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-row v-if="typeCalendar == 2"
                    style="background: white; width: 98%; margin: 0 auto; margin-left: 1%; margin-right: 1%; margin-top: 30px; margin-bottom: 30px;" >
                    <v-col class="spacerow">
                        <v-sheet height="600">
                            <calendar
                                v-model="currentDate"
                                @click:event="showEvent"
                                @click:time="openModalByTime"
                                :events="events2"
                                height="500px"
                                type="week"
                                hide-controls
                                hide-action>
                            </calendar>
                            <v-menu v-model="selectedOpen" max-width="400px" :close-on-content-click="false" :activator="selectedElement"
                                offset-x>
                                <v-card color="grey lighten-4" min-width="30px" flat>
                                    <v-toolbar :color="selectedEvent.color" dark>
                                        <v-btn icon @click="delSelectedOpen"
                                            v-if="selectedEvent.name != 'Cita de vacunación' && selectedEvent.name != 'Cita de medicación' && selectedEvent.name != 'Cita médica' && selectedEvent.name != 'Cita de peluquería'">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <v-card-text class="m-1" style="background: white;">
                                        <span  v-html="selectedEvent.details"></span>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn text color="secondary" @click="selectedOpen = false">
                                            Cerrar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </v-sheet>
                    </v-col>
                </v-row>

                <v-dialog v-model="showForm" width="60%" scrollable>
                    <v-card>
                        <v-row>
                            <v-col>
                                <v-card-title class="mx-1 my-1">
                                    Agregar cita de veterinaria
                                </v-card-title>
                            </v-col>
                            <v-col cols="3" md="3" class="d-flex justify-end mx-5 my-5">
                                <v-btn color="error" dark @click="showForm = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-text>
                            <v-row v-if="showTablePatients" v-show="selectedPatient == null">
                                <v-col class="mt-5">
                                    <p style="font-size: 18px;">Seleccione el paciente al que quiere agendar una cita de peluqueria:</p>
                                    <GeneralFilter v-model="filterPatients" :filterEndpoint="$API.veterinaryCalendar.getPatientWithCustomer" :search="{filter: filterPatients}"
                                        @emptyFilter="getPatientWithCustomer" @filtered="filterHandlerPatients">
                                        <DxDataGrid :data-source="patientsWithCustomer" :scrolling="{ useNative: true }">
                                            <DxPaging :page-size="5" />
                                            <DxColumn caption="" :width="50" cell-template="select-patient"></DxColumn>
                                            <DxColumn data-field="ID_Patient" caption="ID Paciente"></DxColumn>
                                            <DxColumn data-field="NamePatient" caption="Paciente"></DxColumn>
                                            <DxColumn data-field="FullName" caption="Cliente"></DxColumn>
                                            <DxColumn data-field="OldID" caption="Código Alterno"></DxColumn>
                                            <template #select-patient="{ data }">
                                                <div>
                                                    <v-checkbox @change="handleSelection(data.data)"/>
                                                </div>
                                            </template>
                                        </DxDataGrid>
                                    </GeneralFilter>
                                </v-col>
                            </v-row>
                            
                            <template v-if="showTablePatients && selectedPatient != null">
                                <v-row class="mb-2">
                                    <v-col cols="6">
                                        <v-btn color="comment" dark @click="selectedPatient = null" ><v-icon>mdi-arrow-left</v-icon> Regresar</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="appointmentForm.ID_Patient"
                                            label="ID Paciente" disabled></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="appointmentForm.ID_Customer"
                                            label="ID Cliente" disabled></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="selectedPatient.NamePatient"
                                            label="Paciente" disabled></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="selectedPatient.FullName"
                                            label="Cliente" disabled></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="appointmentForm.AppointmentStartDate"
                                            label="Fecha cita:" disabled></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="appointmentForm.AppointmentStartHour"
                                            label="Hora inicio" disabled></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="appointmentForm.AppointmentEndHour"
                                            label="Hora Fin" disabled></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="appointmentForm.AppointmentName"
                                            label="Titulo de cita" disabled></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-select outlined dense :items="services" v-model="appointmentForm.GroomingTypeOfService"
                                        label="Tipo de servicio" item-text="label" item-value="value" attach>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select outlined dense attach :items="sizes" v-model="appointmentForm.GroomingSize"
                                        label="Selecciona tamaño" item-text="label" item-value="value">
                                        </v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="appointmentForm.GroomingGroomer"
                                            label="Peluquero"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field outlined dense v-model="appointmentForm.GroomingVisit"
                                            label="Visita"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-textarea outlined dense v-model="appointmentForm.AppointmentDetails"
                                            label="Detalle de la cita"></v-textarea>
                                    </v-col>
                                </v-row>
                            </template>
                            
                            <template v-if="!showTablePatients">
                                <v-row class="mt-5">
                                    <v-col>
                                        <v-text-field outlined dense v-model="appointmentForm.AppointmentName"
                                            label="Titulo de cita"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-textarea outlined dense v-model="appointmentForm.AppointmentDetails"
                                            label="Detalle de la cita"></v-textarea>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <date-picker outlined dense label="Fecha de inicio"
                                            @dateSelected="(param) => (appointmentForm.AppointmentStartDate = param)"
                                            :value="appointmentForm.AppointmentStartDate"></date-picker>
                                    </v-col>

                                    <v-col>
                                        <v-flex>
                                            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                                :return-value.sync="appointmentForm.AppointmentStartHour" lazy transition="scale-transition" offset-y full-width
                                                max-width="290px" min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field outlined dense v-model="appointmentForm.AppointmentStartHour" label="Hora de inicio"
                                                    readonly v-on="on"></v-text-field>
                                                </template>
                                                <v-time-picker outlined dense v-if="menu2" v-model="appointmentForm.AppointmentStartHour" full-width
                                                    @click:minute="$refs.menu2.save(appointmentForm.AppointmentStartHour)"></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <date-picker outlined dense label="Fecha fin"
                                            @dateSelected="(param) => (appointmentForm.AppointmentEndDate = param)"
                                            :value="appointmentForm.AppointmentEndDate"></date-picker>
                                    </v-col>

                                    <v-col>
                                        <v-flex>
                                            <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false" :nudge-right="40"
                                                :return-value.sync="appointmentForm.AppointmentEndHour" lazy transition="scale-transition" offset-y full-width
                                                max-width="290px" min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field outlined dense v-model="appointmentForm.AppointmentEndHour" label="Hora de fin"
                                                    readonly v-on="on"></v-text-field>
                                                </template>
                                                <v-time-picker outlined dense v-if="menu3" v-model="appointmentForm.AppointmentEndHour" full-width
                                                    @click:minute="$refs.menu3.save(appointmentForm.AppointmentEndHour)"></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-autocomplete attach outlined dense v-model="appointmentForm.ID_Customer" :items="customers" item-text="NameCustomer" item-value="ID_Customer" label="Seleccione el cliente" v-on:change="getPatient" ></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-autocomplete attach outlined dense v-model="appointmentForm.ID_Patient" :items="patients" item-text="NamePatient" item-value="ID_Patient" label="Seleccione el paciente" ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </template>
                            
                            
                            
                        </v-card-text>
                        <v-card-actions class="d-flex flex-row-reverse ">
                            <template v-if="showTablePatients && selectedPatient != null">
                                <v-btn color="save" dark @click="addAppointmentDate">
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                            </template>

                            <template v-if="!showTablePatients">
                                <v-btn color="save" dark @click="addAppointmentDate">
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                            </template>
                            
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
                </alerts>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>

import DatePicker from '../../components/DatePicker.vue';
import Alerts from '../../components/Alerts';
import { DxDataGrid, DxColumn, DxPaging } from "devextreme-vue/data-grid";
import GeneralFilter from "@/components/GeneralFilter";
//import Vue from 'vue';

export default {
    name: "VeterinaryCalendar",
    components: { DatePicker, DxPaging, DxColumn, Alerts, DxDataGrid, GeneralFilter },
    props: ["win"],
    data() {
        return {
            currentDate: '',
            reloadView: 0,
            locale: 'es',
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Vista por mes',
                week: 'Vista por semana',
                day: 'Vista por día',
                '4day': '4 Days',
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            events2: [],
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            showForm: false,
            appointmentForm: {
                AppointmentName: '',
                AppointmentDetails: '',
                AppointmentStartDate: new Date(),
                AppointmentEndDate: new Date(),
                AppointmentStartHour: '07:00',
                AppointmentEndHour: '07:30',
                ID_Patient: null,
                ID_Customer: null
            },
            menu2: false,
            menu3: false,
            modal2: false,
            modal3: false,
            customers: [],
            patients: [],
            alert: {
                type: null,
                show: false,
                header: "",
                body: "",
            },
            policlinicaclientdata: JSON.parse(localStorage.getItem("user")).policlinicaclientdata,
            typeCalendar: 0,
            showTablePatients: false,
            patientsWithCustomer: [],
            filterPatients: '',
            selectedPatient: null,
            services: [
                { label: 'Baño', value: "Baño" },
                { label: 'Baño Medicado', value: "Baño Medicado" },
                { label: 'Corte', value: "Corte" },
                { label: 'Corte de uñas', value: "Corte de uñas" },
                { label: 'Corte y Baño', value: "Corte y Baño" },
                { label: 'Corte y Baño Medicado', value: "Corte y Baño Medicado" },
                { label: 'Vaciamiento de glándulas adanales', value: "Vaciamiento de glándulas adanales" },
                { label: 'Recorte de pelo de orejas', value: "Recorte de pelo de orejas" },
                { label: 'Lavado de dientes', value: "Lavado de dientes" },
            ],
            sizes: [
                { label: 'Grande', value: "Grande" },
                { label: 'Mediano', value: "Mediano" },
                { label: 'Pequeño', value: "Pequeño" },
            ],
        }
    },
    mounted() {
        this.getCustomer()
        this.getPatientWithCustomer()
        this.$refs.calendar.checkChange()
    },
    methods: {
        customIntervalFormat(interval) {
            return `${interval.hour}:${interval.minute < 10 ? '0' : ''}${interval.minute}`;
        },
        showAlert(type, header, body, options = null){
            type = (type == null) ? "danger" : type
            this.alert.type = type
            this.alert.header = header
            this.alert.body = body
            this.alert.show = true
            this.alert.options = (options != null) ? options : null
        },
        closeAlert() {
            this.alert.show = false
            if (this.alert.options == 'add') {
                this.updateRange()
                this.appointmentForm.AppointmentStartHour = '07:00'
                this.appointmentForm.AppointmentEndHour = '07:30'
                this.appointmentForm.AppointmentName = null
                this.appointmentForm.AppointmentDetails = null
                this.appointmentForm.AppointmentStartDate = null
                this.appointmentForm.AppointmentEndDate = null
                this.appointmentForm.ID_Customer = null
                this.appointmentForm.ID_Patient = null
            }
        },
        async getCustomer(){
            this.$API.veterinaryCalendar.getCustomer().then((response) => {
                this.customers = response
            }).catch((err) => {
                console.log(err)
            })
        },
        async getPatient(){
            this.$API.veterinaryCalendar.getPatient({ ID_Customer: this.appointmentForm.ID_Customer }).then((response) => {
                this.patients = response
                console.log('INFO PATIENT', response)
            }).catch((err) => {
                console.log(err)
            })
        },
        async getPatientWithCustomer() {
            this.$API.veterinaryCalendar.getPatientWithCustomer({ filter: this.filterPatients })
            .then(response => {
                this.patientsWithCustomer = response  
            })
        },
        viewDay({ date }) {
            this.focus = date
            this.type = 'day'
        },
        getEventColor(event) {
            return event.color
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        updateRange(dateRange) {
            const dateStart = dateRange.start.date;
            const dateEnd = dateRange.end.date
            const events = []
            const events2 = []
            this.$API.veterinaryCalendar.getAppointmentsOfVeterinaryForms({dateStart, dateEnd}).then((response) => {

                for (let i = 0; i < response.prescription.length; i++) {
                    events.push({
                        name: "Cita médica",
                        details: "<b>Cliente: </b> " + response.prescription[i].NameCustomer + "<br/><b>Paciente: </b>" + response.prescription[i].NamePatient + "<br/><b>Próxima cita: </b>" + response.prescription[i].NextAppointment,
                        start: this.setDate(response.prescription[i].NextAppointment),
                        end: this.setDate(response.prescription[i].NextAppointment),
                        color: 'green',
                        timed: false
                    })
                }

                for (let i = 0; i < response.vaccineAndMedication.length; i++) {
                    let vaccOrMedicine1 = response.vaccineAndMedication[i].ItemType == 'VAC' ? "vacunación" : 'medicación'
                    let vaccOrMedicine2 = response.vaccineAndMedication[i].ItemType == 'VAC' ? "la vacuna" : 'el medicamento'
                    let text = "<b>Nota:</b> Se espera su visita para recibir " + vaccOrMedicine2 + " " + response.vaccineAndMedication[i].NameItem
                    events.push({
                        name: "Cita de " + vaccOrMedicine1,
                        details: "<b>Cliente: </b> " + response.vaccineAndMedication[i].NameCustomer + "<br/><b>Paciente: </b>" + response.vaccineAndMedication[i].NamePatient + "<br/>" + text,
                        start: this.setDate(response.vaccineAndMedication[i].NextAppointment),
                        end: this.setDate(response.vaccineAndMedication[i].NextAppointment),
                        color: 'blue',
                        timed: false
                    })
                }

                for (let i = 0; i < response.grooming.length; i++) {
                    events.push({
                        name: "Cita de peluquería",
                        details: "<b>Cliente: </b> " + response.grooming[i].NameCustomer + "<br/><b>Paciente: </b>" + response.grooming[i].NamePatient + "<br/><b>Peluquero: </b>" + response.grooming[i].Groomer,
                        start: this.setDate(response.grooming[i].NextAppointment),
                        end: this.setDate(response.grooming[i].NextAppointment),
                        color: 'purple',
                        timed: false
                    })

                    events2.push({
                        name: "Cita de peluquería",
                        patient: response.grooming[i].NamePatient,
                        customer: response.grooming[i].NameCustomer,
                        details: "<b>Cliente: </b> " + response.grooming[i].NameCustomer + "<br/><b>Paciente: </b>" + response.grooming[i].NamePatient + "<br/><b>Peluquero: </b>" + response.grooming[i].Groomer,
                        start: this.setDate(response.grooming[i].NextAppointment),
                        end: this.setDate(response.grooming[i].NextAppointment),
                        color: 'purple',
                        timed: false
                    })
                }

                for (let i = 0; i < response.calendar.length; i++) {
                    events.push({
                        id: response.calendar[i].ID,
                        name: response.calendar[i].AppointmentName,
                        details: "<b>Cliente: </b> " + response.calendar[i].NameCustomer + "<br/>" +
                            "<b>Paciente: </b> " + response.calendar[i].NamePatient + "<br/>" +
                            "<b>Detalles de la cita: </b> " + response.calendar[i].AppointmentDetails,
                        start: this.setDateWithHour(response.calendar[i].AppointmentStartDate, response.calendar[i].AppointmentStartHour),
                        end: this.setDateWithHour(response.calendar[i].AppointmentEndDate, response.calendar[i].AppointmentEndHour),
                        color: 'grey darken-1',
                        timed: true
                    })

                    if (response.calendar[i].SpecialStatus == 'grooming') {
                        events2.push({
                            id: response.calendar[i].ID,
                            name: response.calendar[i].AppointmentName,
                            patient: response.calendar[i].NamePatient,
                            customer: response.calendar[i].NameCustomer,
                            appointmentDetails: response.calendar[i].AppointmentDetails,
                            details: "<b>Cliente: </b> " + response.calendar[i].NameCustomer + "<br/>" +
                                "<b>Paciente: </b> " + response.calendar[i].NamePatient + "<br/>" +
                                "<b>Tipo de servicio: </b> " + response.calendar[i].GroomingTypeOfService + "<br/>" +
                                "<b>Tamaño: </b> " + response.calendar[i].GroomingSize + "<br/>" +
                                "<b>Peluquero: </b> " + response.calendar[i].GroomingGroomer + "<br/>" +
                                "<b>Visita: </b> " + response.calendar[i].GroomingVisit + "<br/>" +
                                "<b>Detalles de la cita: </b> " + response.calendar[i].AppointmentDetails,
                            start: this.setDateWithHour(response.calendar[i].AppointmentStartDate, response.calendar[i].AppointmentStartHour),
                            end: this.setDateWithHour(response.calendar[i].AppointmentEndDate, response.calendar[i].AppointmentEndHour),
                            color: 'purple',
                            timed: true
                        })
                    }
                }

                this.events = events
                this.events2 = events2
            }).catch((err) => {
                console.log(err)
                this.events = events
                this.events2 = events2
            })
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },

        setDate(date) {
            const dateStr = date;

            const splitDate = dateStr.split('/');
            const day = parseInt(splitDate[0], 10);
            const month = parseInt(splitDate[1], 10) - 1; // Resta 1 al mes porque en JavaScript los meses van de 0 a 11
            const year = parseInt(splitDate[2], 10);

            const dateFormat = new Date(year, month, day);

            return dateFormat
        },

        setDateWithHour(dateStr, hourStr) {
            const [day, month, year] = dateStr.split('/').map(Number);
            const [hour, minute] = hourStr.split(':').map(Number);

            //const date = new Date(year, month - 1, day, hour, minute);
            const utcDate = new Date(Date.UTC(year, month - 1, day, hour, minute));
            return utcDate
        },

        updateDateTime() {
            if (this.selectedDate && this.selectedTime) {
                const [hours, minutes] = this.selectedTime.split(':');
                this.selectedDate.setHours(Number(hours), Number(minutes), 0, 0);
            }
        },

        addAppointmentDate(){
            this.$API.veterinaryCalendar.postAddAppointment({ appointment: this.appointmentForm }).then(response => {
                this.showForm = false
                this.showAlert("success", "Exito", response.message, "add")
            })  
            .catch(err => {
                this.showAlert("danger", "Error", err.message)
            })
        },

        delSelectedOpen() {
            if (this.selectedEvent){
                const eventIdToDelete = this.selectedEvent.id;

                this.$API.veterinaryCalendar.postDelAppointment({ ID_Appointment: eventIdToDelete}).then(response => {
                    this.events = this.events.filter(event => event.id !== eventIdToDelete)
                    this.events2 = this.events2.filter(event => event.id !== eventIdToDelete)
                    this.selectedOpen = false
                    this.showAlert("success", "Exito", response.message)
                })
                .catch(err => {
                    this.selectedOpen = false
                    this.showAlert("danger", "Error", err.message)
                })
                
            }
        },

        openModalByButton() {
            const selectedTime1 = new Date(`January 1, 2024 07:00`);
            const selectedTime2 = new Date(`January 1, 2024 07:30`);
            let formattedTime1 = selectedTime1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            let formattedTime2 = selectedTime2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            const currentDate = new Date();
            const day = String(currentDate.getDate()).padStart(2, '0');
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
            const year = currentDate.getFullYear();
            const formattedDateString = `${day}/${month}/${year}`;

            this.appointmentForm.AppointmentName = '';
            this.appointmentForm.AppointmentDetails = '';
            this.appointmentForm.AppointmentStartDate = formattedDateString;
            this.appointmentForm.AppointmentEndDate = formattedDateString;
            this.appointmentForm.AppointmentStartHour = formattedTime1;
            this.appointmentForm.AppointmentEndHour = formattedTime2;
            this.appointmentForm.ID_Patient = null
            this.appointmentForm.ID_Customer = null
            this.appointmentForm.SpecialStatus = null;
            this.appointmentForm.GroomingTypeOfService = null;
            this.appointmentForm.GroomingSize = null;
            this.appointmentForm.GroomingGroomer = null;
            this.appointmentForm.GroomingVisit = null;
            
            this.showTablePatients = false
            this.selectedPatient = null
            this.showForm = true
        },
        openModalByTime({ time, date }) {
            const selectedTime = new Date(`January 1, 2024 ${time}`);
            
            if (selectedTime.getMinutes() >= 30) {
                selectedTime.setMinutes(30);
            } else {
                selectedTime.setMinutes(0);
            }

            let formattedTime = selectedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
            selectedTime.setMinutes(selectedTime.getMinutes() + 30);
            let formattedTime2 = selectedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

            const formattedDate = new Date(date);
            formattedDate.setDate(formattedDate.getDate() + 1); // Suma un día
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
            const year = formattedDate.getFullYear();
            const formattedDateString = `${day}/${month}/${year}`;
            
            this.appointmentForm.AppointmentName = 'Cita peluqueria'
            this.appointmentForm.AppointmentDetails = '';
            this.appointmentForm.AppointmentStartDate = formattedDateString;
            this.appointmentForm.AppointmentEndDate = formattedDateString;
            this.appointmentForm.AppointmentStartHour = formattedTime;
            this.appointmentForm.AppointmentEndHour = formattedTime2;
            this.appointmentForm.ID_Patient = null
            this.appointmentForm.ID_Customer = null
            this.appointmentForm.SpecialStatus = 'grooming';
            this.appointmentForm.GroomingTypeOfService = null;
            this.appointmentForm.GroomingSize = null;
            this.appointmentForm.GroomingGroomer = null;
            this.appointmentForm.GroomingVisit = null;

            this.showTablePatients = true
            this.selectedPatient = null
            this.showForm = true
            
        },
        filterHandlerPatients(response) {
            this.patientsWithCustomer = response
        },
        handleSelection(patient) {
        if (this.selectedPatient === patient) {
            this.selectedPatient = null;
        } else {
            this.appointmentForm.ID_Patient = patient.ID_Patient
            this.appointmentForm.ID_Customer = patient.ID_Customer
            this.selectedPatient = patient;
            // Asignar otros valores según sea necesario
        }
        },
    },
}
</script>
<style>
.v-btn--fab.v-size--small {
    top: 0px !important;
}

@media (max-width: 959px) {
  .spacerow {
    margin-top: 40px!important; /* Ajusta según necesidad */
    margin-bottom: 40px!important; /* Ajusta según necesidad */
  }
}

.v-calendar-daily__interval-text, .v-calendar-daily_head-weekday, .v-calendar-daily_head-day-label {
    font-weight: bold; /* Opcional: para hacer el texto más grueso */
}

</style>